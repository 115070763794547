import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const description = data.site.siteMetadata.description
  const logo = data.logo.childImageSharp.gatsbyImageData

  return (
    <Layout description={description} logo={logo}>
      <Seo title="404: Não encontrei o que você procurava. :(" />
      <div className="post-wrapper">
        <h1>Vixe! Não encontrei o que você procurava.</h1>
        <div className="post-text">
          <p>
            Pode ser que eu tenha removido algo ou que o link não esteja
            correto. Me desculpe!
          </p>
          <p>
            Para não perder a viagem, que tal conferir um dos nossos{" "}
            <Link to="/">últimos textos</Link>?
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  {
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 300, layout: CONSTRAINED)
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
